<template>
    <div class="w-full pb-5">
        <ModalAssignKey
            :modalAssignKeyVisible="modalAssignKeyVisible"
            :selectedEmployeeUuid="employeeUuid"
            @close="modalAssignKeyVisible = false"
            @refresh="refresh"
        />

        <div class="grid grid-cols-2 gap-4">
            <Key v-for="key in keysData" :key="key.uuid" :keyData="key" class="col-span-1" @refresh="refresh" />
        </div>

        <div class="flex justify-center mt-4">
            <el-button type="primary" :disabled="!userCan('update keys')" @click="showAssignKeyModal">
                {{ $t('employee_details.assign_key') }}
            </el-button>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        Key:            () => import(/* webpackChunkName: "EmployeeKeys/Key" */ './components/Key'),
        ModalAssignKey: () => import(/* webpackChunkName: "EmployeeKeys/ModalAssignKey" */ './components/ModalAssignKey'),
    },

    data() {
        return {
            employeeUuid:          this.$route.params.uuid,
            keysData:              [],
            modalAssignKeyVisible: false,
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'keys');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading_keys');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${this.employeeUuid}/keys_holder`);
            this.keysData = data;

            this.$wait.end('loading_keys');
        },

        refresh() {
            this.getAll();
            this.$emit('onKeysNumberChanged');
        },

        showAssignKeyModal() {
            this.modalAssignKeyVisible = true;
        },
    },
};
</script>
